//Products category
export enum ProductCategory {
    badminton = 'badminton',
    baseball = 'baseball',
    basketball = 'koszykówka',
    hockey = 'hokej',
    rugby = 'rugby',
    soccer = 'piłka nożna',
    tennis = 'tenis'
}










